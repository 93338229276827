<template  origin="center center">
  <v-container id="offers_view" fluid class="pt-3">
    <v-row class="pl-4 pr-7 pt-3">

            <!-- Menu -->
            <v-col cols="auto" class="pt-2" >
              <div class="breadcrumb pa-1 ml-3">
                <Breadcrumbs />
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="auto" v-if="viewConfirmed == 'card' || viewConfirmed == undefined">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    icon
                    class="ml-1 textBtn"
                    v-bind="attrs"
                    v-on="on"
                    @click="loadData()"
                  >
                    <v-icon  size="small"> fa-solid fa-rotate </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.active.update") }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="auto"  v-if="viewConfirmed == 'table'"  >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="ml-1 textBtn"
                    style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;"
                    v-bind="attrs"
                    small
                    icon
                    v-on="on"
                     @click="loadDataTable()"
                  >
                      <v-icon  size="small"> fa-solid fa-rotate </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.no_assign.update") }}</span>
              </v-tooltip>
            </v-col>


            <v-col cols="auto"  v-if="!load && trips.length > 0">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :color="viewConfirmed == 'table'  ?  $vuetify.theme.themes.light.neutral : $vuetify.theme.themes.light.primary"
                    small
                    class="pa-3 viewBtn"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="saveModeCard()"
                  >
                    <v-icon  size="medium"> fa-solid fa-hard-drive </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.active.letter_mode") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :color="viewConfirmed == 'card'  ?  $vuetify.theme.themes.light.neutral : $vuetify.theme.themes.light.primary"
                    small
                    class="viewBtn"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="saveModeTable()"
                  >
                    <v-icon  size="medium"> fa-solid fa-table </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.active.table_mode") }}</span>
              </v-tooltip>
            </v-col>

    </v-row>
    <div v-if="viewConfirmed == 'card' || viewConfirmed == undefined"  id="trips_scrl">
      <v-row
        :class="!$vuetify.breakpoint.xs ? 'pl-7 pr-2' : ''"
        align="center"
        justify="center"
        v-if="!load && trips.length > 0"
      >
        <v-col cols="12" class="pt-0">
          <v-container>
            <v-row align="center" justify="center" class="mt-4">
              <Card v-for="(travel, index) in trips" :key="index" :data="travel" />
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <Skeleton v-if="load && trips" />
      <v-row v-if="trips.length == 0 && !load">
        <v-col cols="12" align="center" justify="center">
          <div id="no-result">
            <v-icon>fa-solid fa-triangle-exclamation</v-icon>
            <p>
              <b>{{ $t("views.trips.active.no_result") }}</b>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-card v-intersect="infiniteScrolling"></v-card>
    </div>
    <div v-if="viewConfirmed == 'table'">
        <v-col cols="auto" style="width: 100%;">
          <Table />
        </v-col>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "TripActive",
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.assets"),
    };
  },
  components: {
    Card: () => import(/* webpackChunkName: "Card" */ "@/components/views/Trip/Active/card/Card.vue"),
    Skeleton: () =>
      import(/* webpackChunkName: "Skeleton" */ "@/components/views/Trip/Skeleton.vue"),
    Table: () => import(/* webpackChunkName: "Table" */ "@/components/views/Trip/Active/table/Table.vue"),
    Breadcrumbs: () =>
    import(/* webpackChunkName: "Breadcrumbs" */ "@/components/views/Trip/Active/breadcrumb/BreadCrumb.vue"),

  },
  data() {
    return {
      trips: [],
      load: false,
      page: 0,
      totalPage: 0,
      resultForPage: 10,
      urlCount: "/Travels/TotalRecordsActives",
    };
  },
  mounted() {
    if (!this.token) {
      window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
    }
    setTimeout(() => {
      this.showLoader({ show: false });
    }, 1000);
    this.loadData();
  },
  computed: {
    ...mapState(['reloadTrips']),
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      search: "search/search",
      viewConfirmed: "changeView/viewConfirmed",
      reloadTrips: "reloadTable/reloadTrips",
    }),
    url() {
      return `/Travels/actives/${this.resultForPage}/${this.page}`;
    },
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("changeView", ["saveViewConfirmed"]),
    ...mapActions("reloadTable", ["saveReloadTrips"]),

    async loadData() {
      this.load = true;
      this.page = 0;
      this.trips = await this.service.getData(this.url, this.search);
      this.totalPage = await this.service.getDataCount(
        this.urlCount,
        this.search,
        this.resultForPage
      );
      this.load = false;
    },

    infiniteScrolling() {
      setTimeout(() => {
        let search = this.search == "" ? "" : `?search=${this.search}`;
        if (this.page <= this.totalPage) {
          this.page++;
          this.axios
            .get(this.url + search)
            .then((response) => {
              if (response.data.length) {
                response.data.forEach((item) => this.trips.push(item));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 500);
    },

    saveModeCard() {
      this.saveViewConfirmed({ viewConfirmed: "card" });
    },
    saveModeTable() {
      this.saveViewConfirmed({ viewConfirmed: "table" });
    },

    loadDataTable() {
      this.saveReloadTrips({ reloadTrips: true });
    },


  },
};
</script>
<style type="scss" scoped>
#offers_view {
  height: 100%;
}

#no-result,
#no-result .v-icon {
  color: #0b0045 !important;
  font-size: 2em;
}

.breadcrumb {
  background-color: #fff;
  border-radius: 8px;
}

.viewBtn {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 40px;
}

.textBtn  {
  text-transform: none;
  color: #0b0045 !important;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 35px;
}


</style>
